import React from "react";
import Layout from "../layout";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import openEmail from "../utils";
import openLink from "../utils/openLink";

import "normalize.css/normalize.css";
import "../styles/reset.scss";
import "./about.scss";

const About = () => {
  const data = useStaticQuery(graphql`
    query PageContent {
      contentfulPage(title: { eq: "About" }) {
        contentful_id
        title
        heading {
          heading
        }
        skills
        links
        linkUrLs
        contactDetails
        profileImage {
          gatsbyImageData
        }
      }
    }
  `);
  // An object containing data direct from Contentful "About page only."
  const content = data.contentfulPage;

  const displaySkills = content.skills.map((element, index) => {
    return <li key={index}>{element}</li>;
  });

  const displayLinks = content.links.map((element, index) => {
    return (
      <li
        className="social-media"
        key={index}
        onClick={() => {
          content.linkUrLs[index] !== undefined &&
            openLink(content.linkUrLs[index]);
        }}
      >
        {element}
      </li>
    );
  });

  return (
    <Layout>
      <div className="about-wrapper">
        <div className="about-left-col">
          <div className="heading-container">
            <header>
              <h3>{content.heading.heading}</h3>
            </header>
          </div>
          <div className="profile-image">
            <GatsbyImage
              image={content.profileImage.gatsbyImageData}
              alt="an image of the content creator - Natalie Hopson"
              objectFit="contain"
            ></GatsbyImage>
          </div>
          <div className="contact-container">
            <h3 className="list-heading">EMAIL</h3>
            <span
              className="email"
              onClick={(e) => {
                openEmail(e, content.contactDetails);
              }}
            >
              {content.contactDetails}
            </span>
          </div>
        </div>

        <div className="about-right-col">
          <div className="content-container">
            <h3 className="list-heading">SKILLS</h3>
            <ul>{displaySkills}</ul>
          </div>
          <div className="content-container">
            <h3 className="list-heading">FOLLOW</h3>
            <ul>{displayLinks}</ul>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default About;
